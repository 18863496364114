import SimpleSlider from "./banner";
import Services from "./services";
import Footer from "./footer";
// import Testimonial from "./testimonials";
import ContactForm from "./contact";
import Portfolio from "./portfolio";
import About from "./about";
function Home() {
    return (
        <>
        <SimpleSlider />
        <Services />
        <About />
        <Portfolio />
        {/* <Testimonial /> */}
        <ContactForm />
        <Footer />
        </>

    );
  }
  
  export default Home;
  