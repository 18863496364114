
import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import Slider from 'react-slick';
const Header = [
    {
     title: " We Provide Various Kinds Of Services For You",
     desc: " "
    }
   ];
   const headerItems = Header.map((element, i) => {
    return (
      <div className="col-md-12 header_title" key={i}>
        <h4>{element.title}</h4>
        <p>{element.desc}</p>
      </div>
    );
  });

 const Service_data = [
    {
        id: 1,
        imgurl: "./assets/cloud_new.svg",
        title: "Web Development",
        desc: (<><p>Crafting Digital Solutions for a Connected World</p><p>Building the Web of Tomorrow, Today </p><p>Code. Create. Innovate.</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>At Vaqua Solutions, we specialize in transforming ideas into digital reality. With the rapid advancement of technology, having a strong online presence is essential for businesses of all sizes. Our team of skilled developers and designers works collaboratively to create innovative web solutions that cater to your unique business needs.</p>
            <p>Whether you require a simple informational website, a complex web application, or a feature-rich e-commerce platform, we have the expertise and experience to bring your vision to life. We begin by understanding your goals, target audience, and business objectives. Our experts then meticulously plan the architecture, user experience, and design elements to ensure a seamless and engaging digital experience.</p>
            <p>Using cutting-edge technologies and industry best practices, we develop scalable, secure, and high-performing websites. Our development process involves clean code, mobile responsiveness, and cross-browser compatibility to provide a consistent experience across different devices and platforms. We pay attention to every detail, from intuitive navigation and engaging visuals to seamless functionality and optimized loading speed.</p>
            <p>Throughout the development journey, we prioritize communication, transparency, and collaboration. We keep you informed at every stage of the process, seeking your feedback and incorporating it into the development cycle. Our goal is to not only meet your expectations but to exceed them, delivering a web solution that truly represents your brand and drives your online success.</p>
            <p>Choose Vaqua Solutions as your web development partner and unlock the potential of the digital landscape. Whether you're a startup looking to establish a strong online presence or an established business seeking to revamp your website, we have the expertise and passion to deliver exceptional results. Experience the power of innovative web development with Vaqua Solutions.</p>
          </div>
        )
    },
    {
        id: 2,
        imgurl: "./assets/cloud_new.svg",
        title: "Web Hosting",
        desc: (<><p>Powerful Hosting Solutions for Uninterrupted Online Presence </p><p> Unlock the Potential of Your Website with Reliable Hosting </p><p>Where Speed and Security Meet in Web Hosting</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>Web Hosting: Your website is the face of your business in the digital world, and it deserves reliable hosting solutions that ensure it is always accessible and performs optimally. At Vaqua Solutions, we offer top-notch web hosting services designed to provide uninterrupted access to your website, regardless of the traffic volume or demands.</p>
            <p>Our state-of-the-art data centers are equipped with redundant power sources, advanced cooling systems, and multiple network providers to guarantee maximum uptime. We understand the importance of security in the digital landscape, which is why we implement robust measures to protect your data and defend against potential threats. Our hosting packages come with built-in security features, including firewalls, malware scanning, and SSL certificates, to provide a safe environment for your website and its visitors.</p>
            <p>We believe that speed is paramount when it comes to web hosting. Slow-loading websites can lead to increased bounce rates and frustrated users. That's why our hosting infrastructure is optimized for performance, utilizing the latest caching technologies, content delivery networks (CDNs), and server-level optimizations. Our goal is to ensure that your website loads quickly, providing an exceptional user experience and giving you a competitive edge in the online world.</p>
            <p>We also understand that one size doesn't fit all. That's why we offer flexible hosting plans to cater to your specific needs. Whether you're a small business just starting or a large enterprise with high traffic demands, we have scalable options to accommodate your requirements. Our dedicated support team is available 24/7 to assist you with any technical issues, ensuring a smooth hosting experience.</p>
            <p>Choose Vaqua Solutions as your web hosting provider and unlock the full potential of your online presence. With our reliable hosting solutions, you can focus on your core business while we take care of your website's performance, security, and availability. Trust us to provide a solid foundation for your digital success.</p>
          </div>
        )
    },
    {
        id: 3,
        imgurl: "./assets/cloud_new.svg",
        title: "Content Management Systems (CMS)",
        desc: (<><p>Simplify. Create. Publish. </p><p>Take Control of Your Content with Ease </p><p> Effortless Content Management, Unlimited Possibilities</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>Managing website content should be a seamless and efficient process, allowing you to focus on delivering engaging experiences to your audience. Content Management Systems (CMS) play a crucial role in achieving this goal. At Vaqua Solutions, we understand the importance of a reliable CMS that empowers you to take control of your website's content.</p>
            <p>CMS platforms such as WordPress, Joomla, and Drupal provide user-friendly interfaces that make it easy for non-technical users to create, edit, and publish content without the need for coding knowledge. These CMS solutions offer a range of features and functionalities that simplify content management and enhance website customization.</p>
            <p>With a CMS, you can easily create and manage web pages, blog posts, media files, and navigation menus. The intuitive control panels and visual editors allow you to format content, add images and videos, and apply styling without the need to write code. This flexibility enables you to update your website's content swiftly, keeping it fresh and engaging for your visitors.</p>
            <p>One of the key advantages of CMS platforms is their extensive plugin and extension ecosystems. These CMS communities have developed a vast library of plugins and extensions that add additional functionalities to your website. Whether you need e-commerce capabilities, SEO optimization, social media integration, or multilingual support, you can find plugins and extensions that meet your specific requirements.</p>
            <p>Furthermore, CMS platforms offer themes and templates that allow you to customize the look and feel of your website. You can choose from a wide range of professionally designed themes or work with a web designer to create a custom design that aligns with your brand identity. This level of customization ensures that your website stands out and reflects your unique style.</p>
            <p>At Vaqua Solutions, we understand that each business has unique requirements when it comes to content management. Whether you choose WordPress, Joomla, Drupal, or any other CMS platform, we provide support and guidance to help you make the most of your chosen CMS. We offer services such as CMS setup, theme customization, plugin selection and configuration, and ongoing maintenance and support.</p>
            <p>Choose Vaqua Solutions as your partner in CMS implementation and support. With our expertise, you can leverage the power of popular CMS platforms to streamline your content management process, enhance website customization, and deliver exceptional experiences to your audience. Experience the efficiency and flexibility of content management with our reliable CMS services.</p>

          </div>
        )
    },
    {
        id: 4,
        imgurl: "./assets/cloud_new.svg",
        title: "UI/UX Design Services",
        desc: (<><p>Design that Captivates. Experience that Delights. </p><p>Transforming Ideas into Intuitive Digital Experiences </p><p>Designing Interfaces for the Future of Interaction</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>At Vaqua Solutions, we understand that design is more than just aesthetics; it's about crafting interfaces that captivate users and deliver delightful experiences. Our UI/UX design services combine creativity, innovation, and user-centric principles to create interfaces that leave a lasting impression.</p>
            <p>We believe that a successful digital experience starts with understanding your target audience. Our design process begins with in-depth research and analysis of your users' preferences, behaviors, and needs. We conduct user interviews, create user personas, and map out user journeys to gain valuable insights. This research forms the foundation of our design strategy, enabling us to create interfaces that resonate with your audience.</p>
            <p>Our talented designers bring these insights to life through stunning visual designs and intuitive user interfaces. We pay meticulous attention to every detail, from color schemes and typography to spacing and layout. We ensure that your brand identity is reflected cohesively throughout the design, creating a consistent and memorable experience for your users.</p>
            <p>Usability and accessibility are at the core of our UI/UX design philosophy. We focus on creating interfaces that are easy to navigate, intuitive to use, and accessible to a wide range of users, including those with disabilities. We conduct extensive user testing to validate our design decisions, making iterative improvements to optimize the user experience.</p>
            <p>Our UI/UX design services extend beyond aesthetics. We understand the importance of seamless interactions and engaging experiences in driving user engagement and conversions. By leveraging the latest design trends, interaction patterns, and usability principles, we create interfaces that not only look beautiful but also enhance usability, engagement, and overall satisfaction.</p>
            <p>Choose Vaqua Solutions as your UI/UX design partner and elevate your brand's online presence. Our passion for design, attention to detail, and commitment to delivering exceptional experiences will set your digital products apart from the competition. Let us create interfaces that captivate your users and drive business success.</p>
          </div>
        )
    },
    {
        id: 5,
        imgurl: "./assets/cloud_new.svg",
        title: "Website Analytics and Optimization",
        desc: (<><p>Insights that Drive Growth. Optimization that Delivers Results </p><p> Unlock the Power of Data for Your Online Success </p><p>Elevate Performance, Maximize Conversions</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>In today's digital landscape, data is the key to making informed decisions and achieving online growth. At Vaqua Solutions, we offer comprehensive website analytics and optimization services to help you uncover valuable insights, boost performance, and drive success.</p>
            <p>Our powerful analytics tools provide in-depth metrics and analysis of your website's performance, user behavior, and conversion rates. We dive deep into your data to uncover trends, identify areas for improvement, and gain a deep understanding of your users' interactions with your website. By tracking key performance indicators, such as page views, bounce rates, and conversion funnels, we provide valuable insights that drive data-informed decision-making.</p>
            <p>Armed with these insights, we work closely with you to develop effective strategies to optimize your website. We conduct thorough audits of your website's structure, content, and user experience to identify any bottlenecks or areas that may be hindering your performance. Our team of experts then implements targeted optimizations, including website speed improvements, responsive design enhancements, and user experience refinements.</p>
            <p>A/B testing and conversion rate optimization are also integral parts of our optimization approach. We create data-driven experiments to test different variations of your website's elements, such as headlines, call-to-action buttons, and layout, to identify the most effective configurations for driving conversions. By continuously testing and refining, we help you achieve higher conversion rates and maximize the return on your digital investments.</p>
            <p>At Vaqua Solutions, we believe that optimization is an ongoing process. We monitor and analyze the impact of our optimizations, providing regular reports and insights to track the progress and success of our efforts. Our goal is to continuously improve your website's performance, enhance the user experience, and drive meaningful results for your business.</p>
            <p>Unlock the full potential of your website with our website analytics and optimization services. Trust Vaqua Solutions as your partner in data-driven decision-making and optimization strategies. Let us guide you in achieving remarkable results through the power of analytics and optimization.</p>
          </div>
        )
    },
    {
        id: 6,
        imgurl: "./assets/cloud_new.svg",
        title: "E-commerce Platforms",
        desc: (<><p>Seamless Selling. Endless Possibilities. </p><p>Empower Your Online Business with Powerful E-commerce </p><p>Where Innovation Meets Online Retail</p></>),
        linktext: "Learn More >",
        modalContent: (
          <div>
            <p>In the ever-expanding world of online retail, having a robust and feature-rich e-commerce platform is essential for success. At Vaqua Solutions, we offer e-commerce solutions that empower your online store for endless possibilities.</p>
            <p>Our e-commerce platforms provide a comprehensive set of features to help you build and manage your online store with ease. From inventory management and order processing to customer management and payment gateways integration, we offer a complete suite of tools to streamline your e-commerce operations. Our platforms are designed with scalability in mind, allowing you to handle growing product catalogs, increased traffic, and evolving customer needs.</p>
            <p>We understand that every business is unique, which is why our e-commerce platforms are highly customizable. We work closely with you to understand your specific requirements and tailor the platform to align with your branding, product offerings, and business processes. Whether you need custom product filters, personalized recommendations, or integration with third-party systems, our expert team has the expertise to deliver tailored solutions.</p>
            <p>Security is a top priority in the e-commerce realm. Our platforms incorporate robust security measures to ensure that your customers' sensitive information is protected. We implement secure payment gateways, SSL encryption, and PCI DSS compliance to provide a safe and secure online shopping experience. Rest assured that your customers' trust and privacy are our utmost concern.</p>
            <p>At Vaqua Solutions, we go beyond just building an e-commerce platform. We focus on delivering exceptional user experiences that drive engagement and conversions. Our platforms feature responsive design, intuitive navigation, and optimized checkout processes to provide a seamless and enjoyable shopping journey for your customers. By leveraging data and analytics, we help you understand your customers' behaviors and preferences, allowing you to personalize their experiences and foster long-term relationships.</p>
            <p>Choose Vaqua Solutions as your partner in e-commerce success. Our e-commerce platforms offer the functionality, scalability, and security you need to thrive in the competitive online retail landscape. Experience the power of our e-commerce solutions and unlock your online store's potential for growth and profitability.</p>
          </div>
        )
    }
   ];

   const Services = () => {
    const [selectedService, setSelectedService] = useState(null);
  
    const handleLearnMore = (service) => {
      setSelectedService(service);
    };
  
    const handleCloseModal = () => {
      setSelectedService(null);
    };
    const [isMobileView, setIsMobileView] = useState(false);

    // Check if the viewport width is below the mobile breakpoint (adjust as needed)
    const handleWindowResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    // Add event listener for window resize
    useEffect(() => {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  
    const serviceItems = Service_data.map((service) => (
      <div className="col-md-4 col-sm-6" key={service.id}>
        <div className="col_in">
          <div className="icon_cont">
            <img src={service.imgurl} alt="" />
          </div>
          <h6>{service.title}</h6>
          {service.desc}
          <button
            className="btn btn-white"
            onClick={() => handleLearnMore(service)}
          >
            {service.linktext}
          </button>
        </div>
      </div>
    ));
  
    return (
      <>
        <section className="services_new" id="services_new">
          <div className="container">
            {/* Header */}
            <div className="row">
              {headerItems}
            </div>
    
            {/* Service Items */}
            {isMobileView ? (
              <Slider {...settings}>
                {serviceItems}
              </Slider>
            ) : (
              <div className="row">{serviceItems}</div>
            )}
    
            {/* Modal */}
            <Modal show={selectedService !== null} onHide={handleCloseModal} centered>
              <Modal.Header closeButton>
                <Modal.Title>{selectedService && selectedService.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{selectedService && selectedService.modalContent}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </section>
      </>
    );
  };
  
  export default Services;