import React from 'react';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [formStatus, setFormStatus] = React.useState('Send Message');
  const [captchaValue, setCaptchaValue] = React.useState('');
  const [error, setError] = React.useState('');
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };
  const initialState = {
    name: '',
    email: '',
    subject:'',
    message: ''
  };
  const [formData, setFormData] = React.useState(initialState);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!captchaValue) {
      setError('Please verify that you are not a robot.');
      return;
    }
  
    setFormStatus('Submitting...');
    setError('');
  
    const { name, email, subject, message,action } = e.target.elements;

    
    try {
      await axios.post('https://vaqua.solutions/sendEmail/index.php', {  // Change the URL if the server is running on a different port or domain
        action: action.value,
        name: name.value,
        email: email.value,
        subject: subject.value,
        message: message.value,
      });
      setFormData(initialState);
      setFormStatus('Message Sent');
    } catch (error) {
      console.error(error);
      setFormStatus('Error');
    }
  };
  

  return (
    <>
      <section className="contact_new" id="contact_new">
        <div className="container">
          <div className="row">
            <div className="col-md-12 header_title">
              <h4>Contact Us</h4>
              {/* <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since the 1500s
              </p> */}
            </div>
          </div>

          <div className="container mt-5">
            <form onSubmit={handleSubmit}>
            <input type="hidden" name="action" id="action" value="submitqoute" />
              <div className="mb-3">
                <input className="form-control" type="text" id="name" onChange={handleChange} name="name" value={formData.name} placeholder="Your Name" required />
              </div>
              <div className="mb-3">
                <input className="form-control" type="email" id="email" onChange={handleChange} name="email" value={formData.email} placeholder="Your Email" required />
              </div>
              <div className="mb-3">
                <input className="form-control" type="text" id="subject" onChange={handleChange} name="subject" value={formData.subject} placeholder="Subject" required />
              </div>
              <div className="mb-3">
                <textarea className="form-control" id="message" name="message" onChange={handleChange} value={formData.message} placeholder="Message" required />
              </div>
              <ReCAPTCHA sitekey="6LcgqVsmAAAAAHZoDu6qdxJCyllfe8rQ52sk_JEx" onChange={handleCaptchaChange} />
              {error && <p className="text-danger">{error}</p>}
              <button className="btn btn-danger" type="submit">
                {formStatus}
              </button>
            </form>
          </div>
        </div>
      </section> 
    </>
  );
};

export default ContactForm;