import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Header = [
   {
    "title": " We Provide Various Kinds Of Services For You",
    "desc": " "
   }
  ];
  const headerItems = Header.map((element, index) => (
    <React.Fragment key={index}>
      <h4>{element.title}</h4>
      <p>{element.desc}</p>
    </React.Fragment>
  ));
  

const portfolioData = [
    {
        imgurl: './assets/holle.png',
        title:'Shopify Ecommerce',
    },
    {
        imgurl: './assets/omg-glass.png',
        title:'Shopify Ecommerce',
    },
    {
        imgurl: './assets/aaa.png',
        title:'CMS , Inhouse built on Codeigniter Framework',
    },
    {
        imgurl: './assets/sass.png',
        title:'CMS, Wordpess',
    }
    ,
    {
        imgurl: './assets/pro-junk.png',
        title:'CMS , Inhouse built on Codeigniter Framework',
    }
    ,
    {
        imgurl: './assets/scarpe.png',
        title:'Shopify Ecommerce',
    }
   ];
   const portfolioItems = portfolioData.map((element, index) => (
    <div className="col-md-3 col-sm-3" key={index}>
      <div className="col_in">
        <img src={element.imgurl} alt="" />
        <div className="on_hov">
           <div className="on_hov_in">
              <h5>{element.title}</h5>
           </div>
        </div>
      </div>
    </div>
  ));
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };  
  
  const portfolioSlider = (
    <Slider {...settings}>
      {portfolioItems}
    </Slider>
  );
function Portfolio() {
    return (
        <>
        <section className="portfolio_new" style={{  backgroundImage: `url("./assets/Group 16.png")` }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 header_title">
                       {headerItems}
                    </div>
                    {portfolioSlider}
                </div>

            </div>
        </section>
        </>
    );
  }
  
  export default Portfolio;