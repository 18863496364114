function Footer() {
    return (
        <>
<footer className="py-5">
  <div className="container">
    <div className="row">
      <div className="col-12  col-md-12 mb-3">
        <p>Copyright © Vaqua Solutions all rights reserved.</p>
        {/* <ul className="nav flex-column">
          <li className="nav-item mb-2"><span>Address:</span><a href="/#" className="nav-link p-0 ">Example@gmail.com</a></li>
          <li className="nav-item mb-2"><span>Phone:</span><a href="/#" className="nav-link p-0 ">Example@gmail.com</a></li>
          <li className="nav-item mb-2"><span>Email:</span><a href="/#" className="nav-link p-0 ">Example@gmail.com</a></li>
        </ul> */}
      </div>

      {/* <div className="col-6 col-md-2 mb-3">
        <ul className="nav flex-column ">
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">FAQs</a></li>
        </ul>
      </div>
      <div className="col-6 col-md-2 mb-3">
        <ul className="nav flex-column">
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Home</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Features</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">Pricing</a></li>
          <li className="nav-item mb-2"><a href="/#" className="nav-link p-0 text-muted">FAQs</a></li>
        </ul>
      </div> */}
    </div>

    {/* <div className="d-flex flex-column flex-sm-row justify-content-end border-top">
      <p>Lorem Ipsum is simply © 2021 All rights reserved.</p>
       <ul className="list-unstyled d-flex">
        <li className="ms-3"><a href="/#">
          <svg xmlns="http://www.w3.org/2000/svg" width="8.377" height="16.753" viewBox="0 0 8.377 16.753">
          <path id="facebook" d="M13.035,2.782h1.529V.118A19.75,19.75,0,0,0,12.336,0C10.131,0,8.62,1.387,8.62,3.936V6.283H6.187V9.26H8.62v7.493H11.6V9.261h2.335l.371-2.978H11.6V4.232c0-.861.232-1.45,1.432-1.45Z" transform="translate(-6.187)" fill="#2ad9ff"/>
        </svg></a>
        </li>
        <li className="ms-3"><a href="/#">
          <svg id="instagram-2" xmlns="http://www.w3.org/2000/svg" width="16.758" height="16.758" viewBox="0 0 16.758 16.758">
          <path id="Path_7" data-name="Path 7" d="M12.222,0H4.536A4.541,4.541,0,0,0,0,4.536v7.686a4.541,4.541,0,0,0,4.536,4.536h7.686a4.541,4.541,0,0,0,4.536-4.536V4.536A4.541,4.541,0,0,0,12.222,0ZM8.379,12.961a4.582,4.582,0,1,1,4.582-4.582A4.587,4.587,0,0,1,8.379,12.961Zm4.692-8.084a1.354,1.354,0,1,1,1.354-1.354A1.355,1.355,0,0,1,13.071,4.878Zm0,0" fill="#fff"/>
          <path id="Path_8" data-name="Path 8" d="M149.1,146.02a3.076,3.076,0,1,0,3.076,3.076A3.079,3.079,0,0,0,149.1,146.02Zm0,0" transform="translate(-140.717 -140.717)" fill="#fff"/>
        </svg></a>
        </li>
        <li className="ms-3"><a href="/#">
          <svg xmlns="http://www.w3.org/2000/svg" width="20.62" height="16.753" viewBox="0 0 20.62 16.753">
          <path id="twitter" d="M18.5,6.428A8.438,8.438,0,0,0,20.62,4.234h0a8.828,8.828,0,0,1-2.436.668,4.2,4.2,0,0,0,1.86-2.336,8.458,8.458,0,0,1-2.681,1.023,4.227,4.227,0,0,0-7.312,2.89,4.353,4.353,0,0,0,.1.964A11.968,11.968,0,0,1,1.436,3.02a4.227,4.227,0,0,0,1.3,5.65,4.178,4.178,0,0,1-1.91-.521V8.2a4.247,4.247,0,0,0,3.387,4.154,4.2,4.2,0,0,1-1.108.139,3.731,3.731,0,0,1-.8-.072,4.269,4.269,0,0,0,3.95,2.945,8.5,8.5,0,0,1-5.242,1.8A8,8,0,0,1,0,17.106,11.9,11.9,0,0,0,6.485,19,11.949,11.949,0,0,0,18.5,6.428Z" transform="translate(0 -2.25)" fill="#2ad9ff"/>
        </svg>
        </a>
        </li>
      </ul> 
    </div> */}
    </div>
  </footer>

  </>

    );
  }
  
  export default Footer;