import "bootstrap/js/src/collapse.js";
const headerLinks = [
  {
      "linktext": "Home",
      "linkurl": "/#"
  },
  {
    "linktext": "Services",
    "linkurl": "/#services_new"
  },
  {
    "linktext": "About Us",
    "linkurl": "/#about_us"
  },
 ];
 const navElements = headerLinks.map((element, index) => (
  <li className="nav-item" key={index}>
    <a className="nav-link" href={element.linkurl}>
      {element.linktext}
    </a>
  </li>
));

function Header() {
    return (
        <>
<nav className="navbar navbar-expand-lg navbar-light bg-light">
  <div className="container">
    <a className="navbar-brand" href="#">
      <img src="./assets/Asset_1@4x.png"/>
    </a>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mx-auto mb-2 mb-lg-0">
        {navElements} 
      </ul>
      <form className="d-flex">
        <a href="/#contact_new" className="btn btn-info" type="submit">Contact US</a>
      </form>
    </div>
  </div>
</nav>
        </>

    );
  }
  
  export default Header;
