import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <>
    <section className="hero_section">
    <div className="img_bck">
        <img src="./assets/web-bannr.jpg" className="img-fluid"/>
    </div>
    <div className="slider_home">
    <Slider {...settings}>
      <div>
        <div className="container">
            <div className="row">
                <div className="col-md-7">
                    <div className="tex_in">
                        <h1>Coding the Future of Business.</h1>
                        <p>Elevating Businesses with Expert Web Development Services. Our skilled team of web developers crafts stunning, user-friendly websites that captivate audiences and drive online success. With a focus on modern design, seamless functionality, and optimal performance, we deliver tailored web solutions that enhance your brand's online presence. Trust Vaqua Solutions to bring your vision to life and create a powerful digital platform for your business.</p>
                        <a href="#contact_new">Send Message</a>
                    </div>
                </div>
                <div className="col-md-5">
                <img src="./assets/home1-1.png" className="img-fluid"/>
                </div>
            </div>
        </div>
      </div>
    </Slider>
    </div>
    </section>
    </>
  );
}